import clsx from 'clsx'
import { brandName } from '@brand/config/brand-config'
import {
  ANDROID_APP_URL,
  getFooterLinks,
  IOS_APP_URL,
  TERMS_OF_SERVICE_URL,
  PRIVACY_POLICY_URL,
  MLS_DISCLOSURES_URL,
  FAIR_HOUSING_POLICY_URL,
  NEW_YORK_STATE_FAIR_HOUSING_NOTICE_URL,
} from '@brand/config/footer-links'
import { Logo } from '@brand/slots/logo/logo'
import equalHousingDark from '@brand/static/images/equalhousing-dark.svg'
import Image from 'next/image'
import type { PageProps } from '../../../../app/types'
import { Anchor } from '../../../../components/anchor/anchor'
import type { IPLocation } from '../../../../features/request-data/lib/ip-location/ip-location.types'
import { ScrollTrack } from '../../../../features/tagging/scroll-track'
import { FooterLinkSection } from './footer-link-section-redesign'
import { FooterSocialLinks } from '../../../../features/footer/footer-social-links'
import {
  COPYRIGHT,
  EQUAL_HOUSING_OPPORTUNITY_URL,
} from '../../../../features/footer/footer.const'
import styles from './footer-redesign.module.css'
import type { FooterProps } from '../../../../features/footer/footer.types'
import { getFooterLocationData } from '../../../../features/footer/get-footer-location-data'
import { useFeatureVariable } from '@rentpath/ab-testing-react'
import { ReactComponent as AppleIcon } from '@brand/icons/apple.svg'
import googlePlayStoreIcon from '../../assets/features/footer/google-play-store-icon.png'

export function FooterRedesign({
  ...props
}: FooterProps & {
  ipLocation?: IPLocation | null
  query: PageProps['searchParams']
}) {
  const isAgRedesign =
    useFeatureVariable<number>(['ag_header_redesign', 'version'], 0) > 0

  const { state, city, propertyType } = getFooterLocationData({
    ipLocation: props.ipLocation,
    query: props.query,
  })
  const footerLinks = getFooterLinks({
    state,
    city,
    propertyType,
    isAgRedesign,
  })

  return (
    <ScrollTrack
      as="footer"
      sectionName="scroll_depth"
      itemName="page_bottom"
      role="contentinfo"
      data-tag_section="footer"
      data-tid="footer"
      className={styles.footer}
    >
      <div className={styles.footerTop}>
        <a href="/" title={brandName} aria-label={brandName} data-tid="logo">
          <Logo className={styles.footerLogo} colorScheme="dark" />
        </a>
        <div className={styles.footerMiddle}>
          <div className={styles.footerLinksLeft}>
            {footerLinks.map((section) => {
              return (
                <FooterLinkSection
                  key={section.heading}
                  heading={section.heading}
                  data-tid={section['data-tid']}
                  links={section.links}
                />
              )
            })}
          </div>

          <div className={styles.footerLinksRight}>
            <p className={styles.footerLinksDownloadApp}>Download the App</p>
            <div className={styles.footerLinksApps}>
              <div className={styles.footerLinksApp}>
                <Anchor href={ANDROID_APP_URL}>
                  <Image
                    className={styles.appPromotionStoreIcons}
                    src={googlePlayStoreIcon}
                    alt="Download the ApartmentGuide app from the Google Play App Store"
                    width={20}
                    height={24}
                  />
                  <p className={styles.footerLinksAppText}> Google Play </p>
                </Anchor>
              </div>
              <div className={styles.footerLinksApp}>
                <Anchor href={IOS_APP_URL}>
                  <AppleIcon />
                  <p className={styles.footerLinksAppText}> App Store </p>
                </Anchor>
              </div>
            </div>

            <div className={styles.footerLinksSocial}>
              <FooterSocialLinks isAgRedesign={isAgRedesign} />
            </div>

            <div className={styles.footerDisclosures}>
              <p className={styles.footerLinksAccessibility}>
                If you are using a screen reader, or are having difficulty
                reading this website, please email{' '}
                <Anchor
                  href="mailto:accessibilityfeedback@rent.com"
                  data-tag_item="email_link_accessibility_feedback"
                >
                  accessibilityfeedback@rent.com
                </Anchor>
                .
              </p>
            </div>
          </div>
        </div>
        {props.disclaimer && (
          <p
            className={styles.footerDisclaimer}
            data-tid="footer-property-disclaimer"
          >
            Some of the materials or information displayed on this page may be
            subject to copyright protection by another party. To the extent such
            copyright rights exist, the following notice applies:{' '}
            {props.disclaimer}
          </p>
        )}
        <div className={styles.footerEqualHousing}>
          <Anchor
            href={EQUAL_HOUSING_OPPORTUNITY_URL}
            data-tag_item="equal_housing"
            target="_blank"
            rel="noreferrer"
            title="Equal Housing Opportunity"
            className={styles.footerEqualHousingOpportunityAnchor}
          >
            <Image
              height={34}
              width={34}
              alt="Equal Housing Logo"
              aria-hidden="true"
              unoptimized
              src={equalHousingDark}
              loading="lazy"
            />
          </Anchor>
          <p className={styles.footerEqualHousingCopy}>
            Rent Group, operator of ApartmentGuide, is committed to and abides
            by the Fair Housing Act and Equal Opportunity Act. Read our{' '}
            <Anchor
              href={FAIR_HOUSING_POLICY_URL}
              data-tag_item="fair_housing_policy"
              target="_blank"
              rel="noreferrer"
              className={styles.footerFairHousingAnchor}
            >
              Fair Housing Policy
            </Anchor>{' '}
            and{' '}
            <Anchor
              href={NEW_YORK_STATE_FAIR_HOUSING_NOTICE_URL}
              data-tag_item="new_york_state_fair_housing_notice"
              target="_blank"
              rel="noreferrer"
              className={styles.footerFairHousingAnchor}
            >
              The New York State Fair Housing Notice.
            </Anchor>
          </p>
        </div>
      </div>

      <div className={clsx(styles.footerBottomWrapper, props.className)}>
        <div className={styles.footerBottom}>
          <div className={styles.footerCopyrightGroup}>
            <p>{COPYRIGHT}</p>
          </div>
          <div className={styles.footerCopyrightAnchors}>
            <Anchor
              href={TERMS_OF_SERVICE_URL}
              target="_blank"
              rel="noreferrer"
              data-tag_item="terms_of_service_bottom"
            >
              Terms of Service
            </Anchor>
            <Anchor
              href={PRIVACY_POLICY_URL}
              target="_blank"
              rel="noreferrer"
              data-tag_item="privacy_policy_bottom"
            >
              Privacy Policy
            </Anchor>
            <Anchor
              href={MLS_DISCLOSURES_URL}
              target="_blank"
              rel="noreferrer"
              data-tag_item="mls_disclosures"
            >
              MLS Disclosures
            </Anchor>
          </div>
        </div>
      </div>
    </ScrollTrack>
  )
}
